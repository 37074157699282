/* global window */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, submit, destroy, getFormValues } from 'redux-form';
import { push } from 'react-router-redux';
import { compose } from 'redux';

import matchMediaConnector from '../service/ServiceMatchMedia';
import Headline from '../../components/basics/text/TextHeadline';
import FormWrapper from '../../components/compositions/form/FormWrapper';
import { makeValidate } from '../../helpers/validation';
import { updateQuery } from '../../actions/page/location';
import { showFormError } from '../../actions/formActions';
import FormBlockError from './FormBlockError';
import suitcss from '../../helpers/suitcss';
import { devWarning } from '../../helpers/meta';
import { scrollToHash, scrollToPos } from '../../helpers/navigation';
import { send } from '../../actions/request/send';
import QueueableRequest from '../../model/requests/QueueableRequest';
import FormValidationError from '../../model/errors/FormValidationError';
import * as constants from '../../helpers/constants';
import ProgressBar from '../../components/compositions/checkout/CheckoutProgressBar';
import UnknownFormStepError from '../../model/errors/UnknownFormStepError';
import Copy from '../../components/basics/text/TextCopy';
import { areObjectsEqual } from '../../helpers/objectEquals';

/**
 * Returns a step's config.
 */
export const getStepConfig = (id, formSteps) => formSteps.find(s => s.id === id);

/**
 * Returns true if a step config is included in the list of steps
 */
export const hasStep = (id, formSteps) => Boolean(getStepConfig(id, formSteps));

/**
 * The current step path the user has travelled
 */
export const HISTORY = `${constants.FORM_META_FIELD_PREFIX}_history`;
/**
 * Will trigger a submit of the current step after the form has
 * been initially loaded.
 */
export const INITIAL_AUTO_SUBMIT = `${constants.FORM_META_FIELD_PREFIX}_autoSubmit`;

/**
 * This class provides the means to handle multi-step forms.
 *
 * Examples of multistep forms are
 * the {@link ActivationForm} or the {@link CheckoutForm}
 */
class FormManager extends PureComponent {

  constructor(props) {
    super(props);

    // early bindings
    this.finalSubmit = this.finalSubmit.bind(this);
    this.destructForm = this.destructForm.bind(this);
    this.jumpToStep = this.jumpToStep.bind(this);
    this.makeCancelHandler = this.makeCancelHandler.bind(this);
    this.state = {
      alreadyFilled: [],
    };

    this.renderAsideComponent = this.renderAsideComponent.bind(this);
  }

  componentDidMount() {
    const {
      location,
      currentStepId,
      goToStep,
      form,
      formMeta,
      dispatch,
      isShowStepInUrl,
    } = this.props;

    if (
      isShowStepInUrl &&
      location &&
      location.query[constants.QUERY_FORM_STEP] !== currentStepId
    ) {
      goToStep(currentStepId);
      return;
    }

    const stepConfig = this.getStepConfig(currentStepId);
    if (stepConfig.onStepEnter) {
      dispatch(stepConfig.onStepEnter());
    }

    if (formMeta.isAutoSubmitOnce) {
      dispatch(change(form, INITIAL_AUTO_SUBMIT, false));
      dispatch(submit(form));
    }
  }

  componentDidUpdate(prevProps) {
    const { currentStepId, isInlineForm } = this.props;

    if (currentStepId !== prevProps.currentStepId) {
      scrollToPos(
        isInlineForm ? this.headerContainer.getBoundingClientRect().top + window.pageYOffset : 0,
        isInlineForm ? null : { offset: 0, contentAware: false },
      );
    }
  }

  componentWillUnmount() {
    const { form, dispatch, destroyOnUnmount } = this.props;

    if (destroyOnUnmount) {
      dispatch(destroy(form));
    }
  }

  /**
   * Returns the step configuration based on the step's id.
   *
   * @param {string} id
   */
  getStepConfig(id) {
    return getStepConfig(id, this.props.formSteps);
  }

  /**
   * Normalizes all form values before sending them to the server.
   *
   * Each step's normalize function is called (if it exists) and the
   * normalized values of each step are merged.
   */
  getNormalizedValues(formValues) {
    const { formSteps, fieldMap, stepProps } = this.props;
    const normValues = {};

    formSteps.forEach(step => {
      const stepValues = {};
      Object.keys(step.fieldMap).forEach(field => {
        const fieldName = step.fieldMap[field].name;
        if (formValues[fieldName]) {
          stepValues[fieldName] = formValues[fieldName];
        }
      });
      const normStepValues = step.normalize
        ? step.normalize(step, stepValues, stepProps, this.props)
        : stepValues;
      Object.assign(normValues, normStepValues);
    });

    // remove all meta fields registered in fieldmaps
    Object.keys(fieldMap).forEach(id => {
      const field = fieldMap[id];
      if (field.shouldSubmit === false) {
        delete normValues[field.name];
      }
    });

    // delete form meta fields which are only used to store data for form management
    Object.keys(normValues).forEach(fieldName => {
      if (fieldName.startsWith(constants.FORM_META_FIELD_PREFIX)) {
        delete normValues[fieldName];
      }
    });

    return normValues;
  }

  /**
   * Returns the request object or the request url that
   * defines the request that should be fired when this form is
   * finally submitted.
   *
   * @param {Object} finalizedValues
   * @returns {QueueableRequest|string}
   */
  getSubmitRequest(finalizedValues) {
    const {
      fieldMap,
      formValues,
      submitRoute,
      submitMethod,
      withBlockError,
    } = this.props;

    if (!submitRoute) return null;

    let request;
    if (submitRoute instanceof QueueableRequest) {

      request = submitRoute;

    } else {

      request = typeof submitRoute === 'function'
        ? submitRoute(fieldMap, finalizedValues, formValues)
        : submitRoute;

      if (!(request instanceof QueueableRequest)) {
        devWarning('Prop "submitRoute" should return or create a QueueableRequest object.');

        request = new QueueableRequest(request, {
          payload: finalizedValues,
          method: submitMethod,
        });
      }

    }

    if (withBlockError) {
      // we never show any global dialogs or alerts if we are in a form and
      // the form is able to display response errors.
      request.setPreventDefaultErrorHandling(true);
    }

    return request;
  }

  /**
   * Returns the url to be opened once the form has been submitted successully.
   * The url is either generated via a function or already
   * assumed to be a string itself.
   *
   * @returns {string}
   */
  getSuccessRoute(submitResult) {
    const { successRoute } = this.props;

    return typeof successRoute === 'function' ? successRoute(submitResult) : successRoute;
  }

  /**
   * Calculates the sequence of steps that is reachable starting
   * from the provided step, including the provided step.
   *
   * The step sequence will only include those steps that can be reached in
   * a deterministic way, i.e. the next step is statically set in the step's form
   * config and not dynamically calculated within step's submit function.
   *
   * @param {string} stepId
   * @return {array<Object>}
   */
  getStepsAhead(stepId) {
    const stepsAhead = [];
    do {
      const step = this.getStepConfig(stepId);
      stepsAhead.push(step);
      stepId = step.next; // eslint-disable-line no-param-reassign
    } while (stepId);

    return stepsAhead;
  }

  makeCancelHandler() {
    const { formMeta, goToStep, onCancel } = this.props;
    const previousStepId = formMeta.history[formMeta.history.length - 2];
    return previousStepId ? () => goToStep(previousStepId) : onCancel;
  }

  resetFormFields = async (fields, form) => {
    const { dispatch } = this.props;
    await Object.keys(fields).forEach(field => dispatch(change(form, field, fields[field])));
    // Resets the Error of a field
    // await Object.keys(fields).forEach(field => dispatch(untouch(form, field, fields[field])));
  };

  /**
   * Returns the handler to be called by redux-form on submit fail.
   *
   * When trying to submit a form where the client-side inline-validation fails, redux-form
   * will not bother to call the submit handler but will directly invoke the submitFail
   * handler instead and pass all invalid fields, yet, not passing a submitError. A submitError
   * is only passed in case fields are valid and the actual submit failed.
   *
   * @see https://github.com/erikras/redux-form/blob/master/src/handleSubmit.js
   * @see "onsubmitfail" https://redux-form.com/7.0.4/docs/api/reduxform.md
   *
   * @returns {function(...[*])}
   */
  makeSubmitFailHandler() {
    const { onSubmitWillFail, ui, showError, fieldsToResetOnSubmitFail, form } = this.props;

    return async (fieldErrors, dispatch, submitError) => {
      // we need to rewrap field errors in a ValidationError instance again
      const error = submitError || new FormValidationError(ui, Object.values(fieldErrors));
      if (onSubmitWillFail) {
        const isShowErrors = await onSubmitWillFail(error);
        if (isShowErrors === false) {
          return;
        }
      }

      if (fieldsToResetOnSubmitFail) {
        this.resetFormFields(fieldsToResetOnSubmitFail, form);
      }
      showError(error);
    };
  }

  /**
   * Handles a step's submit action.
   *
   * The handler will first check whether a static "next" property is present for the current
   * step ({@link FormConfig}). Otherwise, it will try to execute the step's `makeSubmit`,
   * which is expected to return a valid step id. If present, the id identifies the
   * step that the form shall navigate to on successs.
   *
   * If no step id is returned, it is assumed that the final form step has
   * been reached and the form is finally submitted.
   *
   * In case of failure, the submit handler is expected to throw a
   * redux-form SubmissionError.
   *
   * @see http://redux-form.com/6.5.0/docs/api/ReduxForm.md
   *
   * @param {Object} step
   * @returns {Function}
   */
  makeSubmitHandler(step) {
    const { goToStep, dispatch } = this.props;
    const { alreadyFilled } = this.state;
    const stepSubmitHandler = step.makeSubmit ? step.makeSubmit(step, this.props) : () => step.next;

    // called by redux form
    return async (formValues) => {
      const nextStepId = await stepSubmitHandler(formValues, dispatch);
      this.setState({
        alreadyFilled: [...alreadyFilled, step.id],
      });
      if (nextStepId) {
        const nextStep = this.getStepConfig(nextStepId);
        const nextEnabledStep = this.getStepsAhead(nextStep.id).filter(s => s.isEnabled).shift();
        goToStep(nextEnabledStep.id);
        return null;
      }

      const returnValue = await this.finalSubmit(formValues);
      setImmediate(() => this.destructForm(returnValue));
      return returnValue;
    };
  }

  /**
   * Will jump to a form step; "jumping" means that we move to a form step that
   * is not necessarily the direct next or previous step.
   *
   * Jumping to steps will only work if a deterministic step sequence exists, i.e.
   * if the next step is statically set in the form config and not dynamically
   * calculated within step's submit function.
   *
   * Behavior: If the target step lies ahead, we move forward step by step until
   * we either reach the desired step or discover an error in an intermediate step.
   * If the step lies behind, we directly jump to it. Disabled steps are ignored.
   *
   * @param {string} targetStepId
   */
  jumpToStep(targetStepId) {
    const {
      goToStep,
      currentStepId,
      formMeta,
      formValues,
    } = this.props;
    const { history } = formMeta;

    if (targetStepId === currentStepId || !this.getStepConfig(targetStepId).isEnabled) {
      return;
    }

    if (history.find(stepId => stepId === targetStepId)) {
      goToStep(targetStepId);
      return;
    }

    const stepsAhead = this.getStepsAhead(currentStepId);
    // get history without current step
    const updatedHistory = history.slice(0, history.length - 1);
    for (let i = 0, l = stepsAhead.length; i < l; i++) {
      const step = stepsAhead[i];
      updatedHistory.push(step.id);

      if (step.id === targetStepId) {
        if (step.isEnabled) {
          goToStep(step.id, { isForceValidation: false, history: updatedHistory });
        }
        return;
      }

      if (!step.isEnabled) {
        continue; // eslint-disable-line no-continue
      }

      const validationResult = this.getStepConfig(step.id).validate(formValues);
      if (validationResult.hasErrors()) {
        goToStep(step.id, { isForceValidation: true, history: updatedHistory });
        return;
      }

      if (step.makeSubmit && !step.isFastForwardAllowed) {
        // not deterministic about which step will follow,
        // we need to abort here and prohibit the jump.
        return;
      }
    }
  }

  /**
   * Submit handler that will be used to submit the form.
   *
   * @see http://redux-form.com/6.5.0/docs/api/ReduxForm.md/ onSubmit
   *
   * @param {Object} formValues
   * @returns Object
   */
  async finalSubmit(formValues) {
    const { onBeforeSubmit, fieldMap, dispatch } = this.props;

    const normValues = this.getNormalizedValues(formValues);
    const finalizedValues = onBeforeSubmit
      ? (await onBeforeSubmit(fieldMap, normValues, formValues)) || normValues
      : normValues;

    // cut leading and tailoring spaces
    Object.keys(finalizedValues).forEach((key) => {
      if ((typeof finalizedValues[key]) === 'string') {
        finalizedValues[key] = finalizedValues[key].trim();
      }
    });

    const request = this.getSubmitRequest(finalizedValues);

    if (!request) {
      return finalizedValues;
    }

    const response = await dispatch(send(request));

    return {
      response,
      request,
      responseBody: response.body.data, // conveniance shortcut
      finalizedValues,
      fieldMap,
      formValues,
    };
  }

  /**
   * Destroys the form and changes the url to the success page.
   *
   * If an `onSubmitSuccess` callback is provided via props, then
   * this callback is invoked before the form is further destructed.
   */
  async destructForm(submitResult) {
    const { dispatch, onAfterSubmitSuccess } = this.props;

    if (typeof onAfterSubmitSuccess === 'function') {
      await onAfterSubmitSuccess(submitResult);
    }

    const successRoute = this.getSuccessRoute(submitResult);

    if (successRoute) {
      dispatch(push(successRoute));
    }
  }

  /**
   * Renders the form's current step.
   *
   * @returns {Object}
   */
  renderStep(stepConfig, AsideComponent) {
    const {
      showError,
      form,
      fieldMap,
      onChange,
      stepProps,
      formValues,
      isSinglePageForm,
      additionalInfo,
    } = this.props;


    const StepComponent = stepConfig.component;
    // eslint-disable-next-line no-shadow
    const validate = formValues => stepConfig.validate(formValues).getFieldErrors();
    // @todo put form props etc. into Form component that has initForm as HOC
    //       and make this component wrap step.component.
    return (
      <div
        className={suitcss({
          descendantName: 'step',
          utilities: [
            AsideComponent && 'col12',
            AsideComponent && 'mCol7',
            AsideComponent && 'lCol8',
            AsideComponent && 'flex',
          ],
          modifiers: [
            additionalInfo && 'withoutAdditionalInfo',
          ],
        }, this)}
      >
        <StepComponent
          // props passed by wrapper component
          {...stepProps}
          // form name, required by redux form
          form={form}
          // called by redux-form
          validate={validate}
          onChange={onChange}
          onSubmit={this.makeSubmitHandler(stepConfig)}
          onSubmitFail={this.makeSubmitFailHandler()}
          // instantiated step config
          config={stepConfig}
          // step dependend handlers
          onCancel={this.makeCancelHandler()}
          // generic handlers
          onStepClick={this.jumpToStep}
          onError={showError}
          // fieldmap containing fields of all steps
          fieldMap={fieldMap}
          // values of all form fields
          formValues={formValues}
          isSinglePageForm={isSinglePageForm}
        />
      </div>
    );
  }

  /**
   * Renders a headline if the `headline` prop is set.
   *
   * @returns {React.Component}
   */
  renderHeadline() {
    const { headline } = this.props;
    if (!headline) {
      return null;
    }

    return (
      <div>
        <Headline
          className={suitcss({ descendantName: 'headline' }, this)}
          utilities={['uppercase', 'weightNormal']}
          element="h1"
          size="l"
          embedded
        >
          {headline}
        </Headline>
      </div>
    );
  }

  renderAdditionallyInfo() {
    const { additionalInfo, isMediaS } = this.props;
    return !isMediaS && additionalInfo ?
      <div
        className={suitcss({
          descendantName: 'additionalInfo',
        }, this)}
      >
        <Copy
          raw
          embedded
          className={suitcss({
            descendantName: 'noteInner',
          }, this)}
        >
          {additionalInfo}
        </Copy>
      </div> : null;
  }

  /**
   * Renders a progressbar if the `withProgressBar` prop is
   * explicitly set to true and multiple steps exist.
   *
   * @returns {React.Component}
   */
  renderProgressBar() {
    const { withProgressBar, formSteps, currentStepId } = this.props;

    if (withProgressBar !== true || !formSteps.length) {
      return null;
    }

    return (
      <ProgressBar
        steps={formSteps}
        currentStep={currentStepId}
        onStepClick={this.jumpToStep}
      />
    );
  }

  renderAsideComponent(isMobileSticky = false) {
    const {
      currentStepId,
      isContractRenewal,
      form,
      isMediaS,
    } = this.props;
    const isStepConfirm = currentStepId === 'confirm';
    const isMobileCheckout = isMediaS && !isContractRenewal && form === 'checkout' && !isStepConfirm;
    const activeConfig = this.getStepConfig(currentStepId);
    const AsideComponent =
        activeConfig.formConfigProps && activeConfig.formConfigProps.asideComponent;

    if (!activeConfig.formConfigProps || !activeConfig.formConfigProps.asideComponent) {
      return null;
    }
    return (
      <div
        className={suitcss({
          descendantName: 'aside',
          utilities: [!isMobileSticky && 'col12', 'mCol5', 'lCol4', 'mlSticky', 'sMarginBottom'],
          modifiers: [isContractRenewal && 'contractRenewal', isMobileSticky && 'checkoutCartSticky'],
        }, this)}
      >
        <AsideComponent
          isMobileCheckout={isMobileCheckout}
          stepId={activeConfig.id}
          {...activeConfig.formConfigProps}
          {...(activeConfig.formConfigProps.asideComponentProps || {})}
        />
      </div>
    );
  }

  renderFooterComponent() {
    const { currentStepId, isContractRenewal } = this.props;
    const isCheckoutCart = (currentStepId === 'shipping' || 'payment') && !isContractRenewal;
    const activeConfig = this.getStepConfig(currentStepId);
    const FooterComponent =
      activeConfig.formConfigProps && activeConfig.formConfigProps.footerComponent;
    if (!activeConfig.formConfigProps || !activeConfig.formConfigProps.footerComponent) {
      return null;
    }

    return (
      <div
        className={suitcss({
          descendantName: 'aside',
          utilities: ['col12', 'mCol5', 'lCol4', 'mlSticky', 'sMarginBottom'],
          modifiers: [isContractRenewal && 'contractRenewal', isCheckoutCart && 'checkoutCartFaqFooter'],
        }, this)}
      >
        <FooterComponent
          stepId={activeConfig.id}
          {...activeConfig.formConfigProps}
          {...(activeConfig.formConfigProps.footerComponentProps || {})}
        />
      </div>
    );
  }

  renderForm(stepConfig, num) {
    const {
      className,
      form,
      currentStepId,
      withBlockError,
      dispatch,
      formValues,
      isMediaS,
      isSinglePageForm,
      formSteps,
      isHardwareSelected,
      onDataCorrection,
    } = this.props;
    const { alreadyFilled } = this.state;

    const isActive = stepConfig === this.getStepConfig(currentStepId);
    const AsideComponent = stepConfig.formConfigProps && stepConfig.formConfigProps.asideComponent;
    const withFooter =
      stepConfig.formConfigProps && stepConfig.formConfigProps.withFooter !== undefined
        ? stepConfig.formConfigProps.withFooter
        : this.props.withFooter;
    const hasRequiredFields = Object.values(stepConfig.fieldMap).some(
      field => field.validation && field.validation.isRequired && !field.isControlled,
    );
    return (
      <FormWrapper
        className={suitcss({
          className,
          modifiers: [
            AsideComponent && 'columns',
            isSinglePageForm && 'singlePageForm',
            isHardwareSelected && 'hardwareSelected',
            stepConfig.id,
          ],
        }, this)}
        config={stepConfig.formConfigProps}
        headline={stepConfig.label}
        componentBefore={isActive && isMediaS ? this.renderAsideComponent : null}
        onSubmit={() => dispatch(submit(form))} // we trigger redux-form's submit from outside
        onCancel={!isSinglePageForm ? this.makeCancelHandler() : null}
        withFooter={withFooter}
        hasRequiredFields={hasRequiredFields}
        open={isActive}
        num={formSteps.length > 1 ? num : null}
        goToStep={() => this.jumpToStep(stepConfig.id)}
        formValues={formValues}
        fieldMap={stepConfig.fieldMap}
        Preview={stepConfig.preview}
        isEditable={alreadyFilled.includes(stepConfig.id)}
        id={stepConfig.id}
        key={stepConfig.id}
      >
        <div
          className={suitcss({ descendantName: 'header' }, this)}
          ref={ref => { this.headerContainer = ref; }}
        >
          {withBlockError &&
            <FormBlockError
              form={form}
              fieldMap={stepConfig.fieldMap}
              onDataCorrection={onDataCorrection}
              formValues={formValues}
            />
          }
        </div>
        <div
          className={suitcss({
            descendantName: 'body',
            utilities: [
              AsideComponent && 'row',
              AsideComponent && 'sDirColumnReverse',
            ],
          }, this)}
        >
          {isActive && this.renderStep(stepConfig, AsideComponent)}
          {isActive && !isMediaS && this.renderAsideComponent()}
        </div>
      </FormWrapper>
    );
  }

  renderSinglePage() {
    const { formSteps } = this.props;
    return (
      <Fragment>
        {formSteps.map((config, num) => this.renderForm(config, num + 1))}
      </Fragment>
    );
  }

  renderMultiPage() {
    const {
      form,
      className,
      currentStepId,
      withBlockError,
      dispatch,
      additionalInfo,
      isHardwareSelected,
      onDataCorrection,
      formValues,
      isContractRenewal,
      isMediaS,
    } = this.props;
    const stepConfig = this.getStepConfig(currentStepId);
    const isStepConfigConfirm = stepConfig.id === 'confirm';

    const AsideComponent = stepConfig.formConfigProps && stepConfig.formConfigProps.asideComponent;
    const FooterComponent =
      stepConfig.formConfigProps && stepConfig.formConfigProps.footerComponent;
    const withFooter =
      stepConfig.formConfigProps && stepConfig.formConfigProps.withFooter !== undefined
        ? stepConfig.formConfigProps.withFooter
        : this.props.withFooter;
    const hasRequiredFields = Object.values(stepConfig.fieldMap).some(
      field => field.validation && field.validation.isRequired && !field.isControlled);
    const isMobileCheckout = isMediaS && !isContractRenewal && form === 'checkout' && !isStepConfigConfirm;

    return (
      <FormWrapper
        className={suitcss({
          className,
          modifiers: [
            AsideComponent && 'columns',
            additionalInfo && 'withoutAdditionalInfo',
          ],
        }, this)}
        config={stepConfig.formConfigProps}
        onSubmit={() => dispatch(submit(form))} // we trigger redux-form's submit from outside
        onCancel={this.makeCancelHandler()}
        withFooter={withFooter}
        hasRequiredFields={hasRequiredFields}
      >
        {
          !additionalInfo && isMobileCheckout &&
          this.renderAsideComponent(true)
        }
        <div
          className={suitcss({ descendantName: 'header' }, this)}
          ref={ref => { this.headerContainer = ref; }}
        >
          {this.renderHeadline()}
          {this.renderProgressBar()}
          {withBlockError &&
            <FormBlockError
              form={form}
              fieldMap={stepConfig.fieldMap}
              onDataCorrection={onDataCorrection}
              formValues={formValues}
            />
          }
        </div>
        <div
          className={suitcss({
            descendantName: 'body',
            utilities: [
              !isContractRenewal && AsideComponent && 'row',
            ],
          }, this)}
        >
          {additionalInfo && this.renderAsideComponent()}
          <div
            className={suitcss({
              descendantName: 'note',
              utilities: [
                AsideComponent && 'col12',
                AsideComponent && 'mCol7',
                AsideComponent && 'lCol8',
              ],
            }, this)}
          >
            {additionalInfo && this.renderAdditionallyInfo(stepConfig, AsideComponent)}
          </div>
          <div
            className={suitcss({
            descendantName: 'step',
            utilities: [
              AsideComponent && 'col12',
              AsideComponent && 'mCol7',
              AsideComponent && 'lCol8',
              AsideComponent && 'flex',
            ],
              modifiers: [
                additionalInfo && 'withoutAdditionalInfo',
                isHardwareSelected && 'hardwareSelected',
              ],
          }, this)}
          >
            {this.renderStep(stepConfig, AsideComponent)}
          </div>
          {
            !additionalInfo && !isMobileCheckout &&
              this.renderAsideComponent()
          }
          {
            !additionalInfo && isMediaS &&
            this.renderFooterComponent(stepConfig, FooterComponent)
          }
        </div>
      </FormWrapper>
    );
  }

  render() {
    const { isSinglePageForm } = this.props;
    if (isSinglePageForm) {
      return this.renderSinglePage();
    }
    return this.renderMultiPage();
  }
}

// @todo move most calculations from mapStateToProps to functions and be selective about changes
// @todo handle props via this.state and use state-proptypes to describe props
FormManager.propTypes = {
  /**
   * Indicates a form that is only a part of the screen and
   * thus doesn't want to have things like scrollToTop on
   * errors.
   */
  isInlineForm: PropTypes.bool,
  /**
   * Callback when the user exits the form
   */
  onCancel: PropTypes.func,
  /**
   * Callback when the form changes (needed for tracking)
   */
  onChange: PropTypes.func,
  /**
   * The onBeforeSubmit function can be used to further add values to the already
   * existing normalized values or to execute some calls before the form is send.
   *
   * signature: `onBeforeSubmit(fieldMap, normalizedValues, rawFormValues): Promise|*`
   */
  onBeforeSubmit: PropTypes.func,
  /**
   * This callback is invoked before the form is further destructed.
   * signature: `onAfterSubmitSuccess({ response, finalizedValues, fieldmap, formvalues, request })`
   */
  onAfterSubmitSuccess: PropTypes.func,
  /**
   * When it is certain that a submit will fail, this function is called
   * before any errors are displayed.
   */
  onSubmitWillFail: PropTypes.func,
  /**
   * This callback is invoked when the form step changes
   * signature: `onStepEnter(form, step, fieldmap, formvalues)`
   */
  onStepEnter: PropTypes.func,
  /**
   * This callback is invoked when the form step is successfully completed
   * signature: `onStepLeave(form, step, fieldmap, formvalues)`
   */
  onStepLeave: PropTypes.func,
  /**
   * Callback when FormBlockError applies corrections
   * signature: `onDataCorrection(formvalues)`
   */
  onDataCorrection: PropTypes.func,
  /**
   * The submit url is either generated via a function or its a string.
   * signature: `successRoute(response)`
   */
  successRoute: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  /**
   * The success url is either generated via a function or its a string.
   * If not provided, nothing is submitted and submit success is simply
   * called with the finalized form values.
   * signature: `submitRoute(fieldMap, finalizedValues, formValues)`
   */
  submitRoute: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.instanceOf(QueueableRequest),
  ]),
  /**
   * Whether or not to display a progressbar
   */
  withProgressBar: PropTypes.bool,
  /**
   * Whether or not to display a block error in case a response failed.
   */
  withBlockError: PropTypes.bool,

  /**
   * Whether or not to display the form footer
   */
  withFooter: PropTypes.bool,
  /**
   * A list of step configs.
   */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      mapStateToFormConfig: PropTypes.oneOfType([
        PropTypes.func, // signature: `mapStateToFormConfig(state, props)`
      ]).isRequired,
    }),
  ).isRequired,
  /**
   * Required by redux-form to initialize the store
   */
  form: PropTypes.string.isRequired,
  /**
   * A fieldMap that contains all fields of all steps.
   */
  fieldMap: PropTypes.object.isRequired,
  /**
   * Various data (mostly content) that is required by the form.
   */
  stepProps: PropTypes.object,
  /**
   * Optional component name that is assigned to the div that
   * wraps the form component.
   */
  className: PropTypes.string,
  formMeta: PropTypes.shape({
    /**
     * A list of step ids that initially defines the travelled step path.
     * For example [ 'step1', 'step2' ] would make the form jump to step2 directly.
     */
    history: PropTypes.array.isRequired,
    isAutoSubmitOnce: PropTypes.bool.isRequired,
  }).isRequired,
  submitMethod: PropTypes.oneOf(['post', 'patch', 'put']),
  currentStepId: PropTypes.string.isRequired,
  formSteps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      fieldMap: PropTypes.object.isRequired,
      validate: PropTypes.func.isRequired,
      /**
       * The normalize function included in the step config allows each step to
       * normalize its step data.
       * signature: `step.normalize(step, stepValues, stepProps)`
       */
      normalize: PropTypes.func,
    }),
  ).isRequired,

  formValues: PropTypes.object.isRequired,
  goToStep: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  // cms wordings
  headline: PropTypes.string,
  location: PropTypes.object,
  ui: PropTypes.object.isRequired,
  destroyOnUnmount: PropTypes.bool,
  isContractRenewal: PropTypes.bool,
  isShowStepInUrl: PropTypes.bool,
  isLinearForm: PropTypes.bool,
  isSinglePageForm: PropTypes.bool,
  isMediaS: PropTypes.bool,
  additionalInfo: PropTypes.string,
  isHardwareSelected: PropTypes.bool,
  fieldsToResetOnSubmitFail: PropTypes.object,
};

FormManager.defaultProps = {
  isInlineForm: false,
  isLinearForm: false,
  withBlockError: true,
  destroyOnUnmount: true,
  submitMethod: 'post',
  stepProps: {},
  isSinglePageForm: false,
};

// @todo move most calculations from mapStateToProps to functions and be selective about changes
/* eslint-disable no-param-reassign */
const mapStateToProps = (state, props) => {
  const {
    steps,
    form,
    stepProps,
    isHideStepInUrl,
    history,
  } = props;
  const { ui, cart } = state;
  const isHardwareSelected = cart.length > 1;
  const formValues = getFormValues(form)(state) || {};
  const isContractRenewal = state.site.contractRenewal.isInProgress;
  let formSteps = steps.map(config => {
    const step = config.mapStateToFormConfig(
      state,
      { ui, ...stepProps, form, formValues }, // @todo shouldn't pass ui; already contained in state
    );

    step.id = step.id || config.id;
    step.next = step.next || config.next;
    step.value = step.id; // @todo legacy remove after careful search!
    step.isEnabled = typeof step.isEnabled === 'function' ? step.isEnabled : () => true;
    step.fieldMap = step.fieldMap || {};

    return step;
  });

  // @todo put into componentwillupdate
  // spread array of fieldmaps to create a total fieldmap with all fields
  // that exist in all form steps
  const fieldMap = Object.assign({}, ...formSteps.map(step => step.fieldMap));

  formSteps = formSteps.map(step => ({
    ...step,
    isEnabled: step.isEnabled(fieldMap, formValues),
    validate: makeValidate(fieldMap, Object.values(step.fieldMap), ui, state),
  }));

  const formMeta = {
    history: formValues[HISTORY] || (history && history.length && history) || [formSteps[0].id],
    isAutoSubmitOnce: !!formValues[INITIAL_AUTO_SUBMIT],
  };

  // make sure we always have a valid step set as currentStep.
  const currentStepId = formMeta.history[formMeta.history.length - 1];

  const isShowStepInUrl = process.browser && formSteps.length > 1 && !isHideStepInUrl;
  return {
    ui,
    isHardwareSelected,
    formSteps,
    formValues,
    formMeta,
    fieldMap,
    isShowStepInUrl,
    currentStepId,
    isContractRenewal,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });
// @todo move most calculations from mapStateToProps to functions and be selective about changes
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    formValues,
    formMeta,
    formSteps,
    isShowStepInUrl,
    currentStepId,
  } = stateProps;
  const { dispatch } = dispatchProps;
  const {
    location,
    form,
    onStepEnter,
    onStepLeave,
  } = ownProps;

  const showError = error => {
    const formStepName = formSteps.length > 1 ? currentStepId : null;
    dispatch(showFormError(form, formStepName, error));
  };
  const goToStep = (step, options = {}) => {
    const {
      isForceValidation = false,
      history = formMeta.history,
    } = options;

    if (!hasStep(step, formSteps)) {
      showError(new UnknownFormStepError());
      return false;
    }

    const stepConfig = getStepConfig(step, formSteps);
    const index = history.indexOf(step);
    const isInHistory = index !== -1;

    if (!isInHistory || isForceValidation) {
      const curStepValidation = getStepConfig(currentStepId, formSteps).validate(formValues);
      if (curStepValidation.hasErrors()) {
        showError(curStepValidation.getError());
        if (stepConfig.onStepError) {
          dispatch(stepConfig.onStepError());
        }
        return false;
      }
    }

    // if visited before, resolve circle, otherwise append
    const updatedHistory = isInHistory ? history.slice(0, index + 1) : [...history, step];
    dispatch(change(form, HISTORY, updatedHistory));

    if (stepConfig.onStepEnter) {
      dispatch(stepConfig.onStepEnter());
    }

    if (onStepEnter) {
      onStepEnter(step, stepConfig.fieldMap, formValues);
    }

    if (onStepLeave) {
      const previousStepId = formMeta.history[formMeta.history.length - 1];
      onStepLeave(previousStepId, stepConfig.fieldMap, formValues);
    }

    if (isShowStepInUrl && location) {
      dispatch(updateQuery(location, { [constants.QUERY_FORM_STEP]: step }));
    }

    scrollToHash(`#${step}`);
    return true;
  };

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    goToStep,
    showError,
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    {
      areStatesEqual: (next, prev) => areObjectsEqual(next, prev, {
        cart: {},
        ui: {},
        form: {},
        site: { contractRenewal: { isInProgress: {} } },
      }, false),
      areOwnPropsEqual: (next, prev) => areObjectsEqual(next, prev, {
        steps: {},
        form: {},
        stepProps: {},
        isHideStepInUrl: {},
        history: {},
        location: {},
        onStepEnter: {},
        onStepLeave: {},
      }, false),
    },
  ),
  matchMediaConnector(['isMediaS', 'isMediaML']),
)(FormManager);
