import React, { memo } from 'react';
import PropTypes from 'prop-types';

import * as promoConfig from './formConfigPromotionCode';
import FormManager from '../form/FormManager';

const PromotionCodeForm = (props) => {
  const { location } = props;

  return (
    <FormManager
      form={PromotionCodeForm.formName}
      location={location}
      steps={[promoConfig]}
      stepProps={props}
    />
  );
};

// note: required by tracking!
PromotionCodeForm.formName = 'PromotionCodeForm';

PromotionCodeForm.propTypes = {
  location: PropTypes.object,
};

export default memo(PromotionCodeForm);
