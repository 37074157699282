import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Link from '../../basics/text/TextLink';
import suitcss from '../../../helpers/suitcss';
import { isTariffEntity } from '../../../helpers/entity';
import { areObjectsEqual } from '../../../helpers/objectEquals';

const componentName = 'CheckoutCartLink';
const CheckoutCartLink = ({ cartBundleCount, ...props }) => (
  <span
    className={suitcss({
      componentName,
      utilities: [cartBundleCount > 0 && 'elementCountAfter'],
    })}
    data-count={cartBundleCount}
  >
    <Link {...props} />
  </span>
);

CheckoutCartLink.propTypes = {
  cartBundleCount: PropTypes.number.isRequired,
};

const mapStateToProps = ({ cart }) => ({
  cartBundleCount: cart.filter(isTariffEntity).length,
});

export default connect(
  mapStateToProps,
  null,
  null,
  {
    areStatesEqual: (next, prev) => areObjectsEqual(next, prev, {
      cart: {},
    }, false),
  },
)(CheckoutCartLink);
