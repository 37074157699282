import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isHardwareGroup,
  isHardwareEntity,
  sortByAvailablePriority,
  getShippingFee,
} from '../../helpers/entity';
import { bindQueryParams } from '../../helpers/url';
import { list as hardwareShapeList, shape as hardwareShape } from '../../propTypes/hardware';

import GlobalSection from '../../components/basics/global/GlobalSection';
import HardwareSelection from '../../components/compositions/hardware/HardwareSelection';
import MediaImageGallery from '../../components/basics/media/MediaImageGallery';
import HardwareInformation from '../../components/compositions/hardware/HardwareInformation';
import { initBookingFromHardware } from '../../actions/dialog/contractRenewalActions';

import { selectItem } from '../../actions/hardware/index';
import { QUERY_SELECTED_TARIFF, QUERY_SELECTED_HARDWARE, DIALOG_TYPE_CUSTOM } from '../../helpers/constants';
import * as cartActions from '../../actions/order/cart';
import * as dialogActions from '../../actions/page/dialog';
import { getEntityById } from '../../selectors/misc';
import { createNormalizeTargetEntitiesSelector } from '../../selectors/entity';
import { areObjectsEqual } from '../../helpers/objectEquals';

class HardwareDetails extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.toggleGallery = this.toggleGallery.bind(this);
  }

  toggleGallery() {
    const { params, showDialog } = this.props;
    showDialog({
      type: DIALOG_TYPE_CUSTOM,
      component: MediaImageGallery,
      props: {
        images: params.gallery,
        withContainer: true,
      },
    });
  }

  render() {
    const {
      name,
      location,
      primaryModule,
      hardwareList,
      selectedHardware,
      selectedTariff,
      params,
      onHardwareSelect,
      onHardwareChange,
      isContractRenewal,
      onContractRenewal,
      reviewActivated,
      isNew,
      shippingFee,
    } = this.props;
    const urlHardwareSelect = bindQueryParams(selectedHardware.urlSelect, {
      [QUERY_SELECTED_HARDWARE]: selectedHardware.eid,
      [QUERY_SELECTED_TARIFF]: selectedTariff && selectedTariff.eid,
    });
    return (
      <div
        data-tracking="hardware"
        data-hardware-id={selectedHardware.iid}
      >
        <GlobalSection>
          <HardwareSelection
            name={name}
            description={params.description}
            primaryModule={primaryModule}
            hardwareList={hardwareList}
            selectedHardware={selectedHardware}
            selectedTariffId={selectedTariff && selectedTariff.eid}
            onBeforeSelect={onHardwareSelect}
            urlSelect={onHardwareSelect ? params.urlShoppingCart : urlHardwareSelect}
            onChange={onHardwareChange}
            location={location}
            onToggleGallery={this.toggleGallery}
            isContractRenewal={isContractRenewal}
            onContractRenewal={onContractRenewal}
            reviewActivated={reviewActivated}
            isNew={isNew}
            shippingFee={shippingFee}
          />
        </GlobalSection>
        <GlobalSection layout="expanded" adjacent>
          <HardwareInformation
            selectedHardware={selectedHardware}
            factsHeadline={params.factsHeadline}
            facts={params.facts}
            details={params.details}
            specs={params.specs}
            reviewActivated={reviewActivated}
          />
        </GlobalSection>
      </div>
    );
  }
}

HardwareDetails.propTypes = {
  name: PropTypes.string.isRequired,
  primaryModule: PropTypes.bool.isRequired,
  isContractRenewal: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    details: PropTypes.array.isRequired,
    factsHeadline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    urlShoppingCart: PropTypes.string.isRequired,
    facts: PropTypes.array.isRequired,
    specs: PropTypes.array.isRequired,
    gallery: PropTypes.array.isRequired,
  }),
  hardwareList: hardwareShapeList.isRequired,
  selectedHardware: hardwareShape.isRequired,
  selectedTariff: PropTypes.shape({
    eid: PropTypes.string.isRequired,
  }),
  location: PropTypes.object.isRequired,
  onHardwareChange: PropTypes.func.isRequired,
  onHardwareSelect: PropTypes.func,
  onContractRenewal: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
  reviewActivated: PropTypes.bool,
  shippingFee: PropTypes.object,
};

HardwareDetails.getSelectedProduct = (state, { entities, location }) => {
  const normalizeTargetEntitiesSelector = createNormalizeTargetEntitiesSelector({ style: 'strike' });
  const hardwareList = entities.filter(isHardwareEntity).sort(sortByAvailablePriority);
  const selectedTariffId = location.query[QUERY_SELECTED_TARIFF];
  const normalizedHardwareList = normalizeTargetEntitiesSelector(
    state,
    [...hardwareList, getEntityById(state.entities, selectedTariffId)],
  );
  const selectedHardware = normalizedHardwareList.find(
    ({ eid }) => eid === location.query[QUERY_SELECTED_HARDWARE],
  );
  const selectedTariff = selectedTariffId && { etype: 'tariffEntity', eid: selectedTariffId };
  return {
    selectedTariff,
    selectedHardware: selectedHardware || normalizedHardwareList[0],
  };
};

function mapStateToProps(state, ownProps) {
  const { entities } = ownProps;
  const isContractRenewal = state.site.contractRenewal.isInProgress;
  const hardwareGroup = entities.find(isHardwareGroup);
  const hardwareList = entities.filter(isHardwareEntity).sort(sortByAvailablePriority);
  const { selectedTariff, selectedHardware } = HardwareDetails.getSelectedProduct(state, ownProps);
  const { user } = state;
  const name = `${hardwareGroup.brand} ${hardwareGroup.name}`;
  // get tariff entity
  const selectedTariffEntity = selectedTariff && getEntityById(state.entities, selectedTariff.eid);
  const shippingFee = getShippingFee(
    selectedHardware,
    selectedTariffEntity,
    state.entities.tariffVO,
    isContractRenewal,
  );
  return {
    name,
    hardwareGroup,
    hardwareList,
    selectedHardware,
    selectedTariff,
    isContractRenewal: state.site.contractRenewal.isInProgress,
    orderProcess: state.orderProcess,
    entities: state.entities,
    reviewActivated: user.gdprPermissions['li-om'],
    isNew: hardwareGroup.new,
    shippingFee,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const onChange = entity => selectItem(ownProps.location, entity);
  return bindActionCreators({
    onHardwareSelect: cartActions.replaceCart,
    onHardwareChange: onChange,
    onContractRenewal: initBookingFromHardware,
    showDialog: dialogActions.showDialog,
  }, dispatch);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { orderProcess } = stateProps;
  const { progressState, entities } = orderProcess;
  const secondState = (
    progressState === 'SINGLE_CONFIRMED' ||
    progressState === 'BUNDLE'
  );
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    // defined on the state of the only if the progressState in the second step
    // please note that if onHardwareSelect is not defined the select button will
    // link to the hardware tariff selection
    onHardwareSelect: secondState ? () => dispatchProps.onHardwareSelect(
      entities.map(entity => getEntityById(stateProps.entities, entity.eid))) : null,
  };
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  {
    areStatesEqual: (next, prev) => areObjectsEqual(next, prev, {
      site: { contractRenewal: { isInProgress: {} } },
      entities: {},
      user: { gdprPermissions: {} },
      orderProcess: {},
    }, false),
    areOwnPropsEqual: (next, prev) => areObjectsEqual(next, prev, {
      entities: {},
      location: {},
    }, false),
  },
)(HardwareDetails);
Container.getSelectedProduct = HardwareDetails.getSelectedProduct;

export default Container;
