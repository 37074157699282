import { debugLog } from './debug';

export const areObjectsEqual = (object1, object2, compareAttributes, _debug = false) => {
  const _areObjectsEqual = (obj1, obj2, cmpAttr, _keyChain = '') => {
    const keys = Object.keys(cmpAttr);

    if (cmpAttr === 'json') {
      const result = JSON.stringify(obj1) === JSON.stringify(obj2);
      if (!result && _debug) {
        debugLog(`not equal [${_debug}]: ${_keyChain}:`, obj1, obj2);
      }
      return result;
    }

    if (keys.length === 0) {
      const result = obj1 === obj2;
      if (!result && _debug) {
        debugLog(`not equal [${_debug}]: ${_keyChain}:`, obj1, obj2);
      }
      return result;
    }
    return keys.map(
      (key) => {
        const compareValue = cmpAttr[key];
        return _areObjectsEqual(obj1?.[key], obj2?.[key], compareValue, `${_keyChain}.${key}`);
      },
    ).reduce((prev, current) => {
      return prev && current;
    }, true);
  };

  const result = _areObjectsEqual(object1, object2, compareAttributes, '');
  if (_debug) {
    debugLog(`areObjectsEqual [${_debug}]: ${result ? 'true' : 'false'}`);
  }
  return result;
};
