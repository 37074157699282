import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import oneLineTrim from 'common-tags/lib/oneLineTrim';

import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';

import { shape as tariffShape } from '../../../propTypes/tariff';

import Copy from '../../basics/text/TextCopy';
import ShoppingCart from '../../../containers/cart/ShoppingCart';
import Headline from '../../basics/text/TextHeadline';
import SuccessInfo from '../form/SuccessInfo';
import { MARKET_POSTPAID, MARKET_PREPAID } from '../../../helpers/constants';
import { send } from '../../../actions/request/send';
import EECCOrderingDocumentRequest from '../../../model/requests/EECCOrderingDocumentRequest';
import EECCTariffChangeDocumentRequest from '../../../model/requests/EECCTariffChangeDocumentRequest';
import { updateCartSimType } from '../../../actions/order/cart';
import { areObjectsEqual } from '../../../helpers/objectEquals';

class CheckoutSummary extends PureComponent {
  constructor(...props) {
    super(...props);
    this.manageDownloadEECCPdf = this.manageDownloadEECCPdf.bind(this);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(updateCartSimType(null));
  }

  async manageDownloadEECCPdf(ev) {
    const {
      dispatch,
      isContractRenewal,
      processId,
      msisdn,
      ui,
    } = this.props;
    if (ev.target.tagName !== 'BUTTON' && ev.target.parentNode.tagName !== 'BUTTON') {
      return;
    }
    ev.preventDefault();
    const format = 'pdf';
    const pdfData = isContractRenewal
      ? await dispatch(send(new EECCTariffChangeDocumentRequest(
        msisdn,
        processId,
        { format },
      )))
      : await dispatch(send(new EECCOrderingDocumentRequest(processId, { format })));

    if (pdfData.status === 200 && process.browser) {
      const { cartTariff } = this.props;
      const isPua = cartTariff && cartTariff.market === MARKET_PREPAID;
      const blob = new window.Blob([pdfData.body.blob], { type: `application/${format};` }); //eslint-disable-line
      const fileName = isPua ? `${ui.guiContractSummary}` : `${ui.guiContractDocument}`;
      FileSaver.saveAs(blob, `${fileName}.${format}`);
    }
  }

  render() {
    const {
      headline,
      copy,
      ui,
      cart,
      cartTariff,
      isCartESimOnly,
    } = this.props;

    const isPua = cartTariff && cartTariff.market === MARKET_PREPAID;
    const isPuc = cartTariff && cartTariff.market === MARKET_POSTPAID;
    const checkoutSummaryPdfLink = copy && copy.replace('{CONTRACT_SUMMARY_PDF}', oneLineTrim`
      <button class="TextLink">
      ${isPua ? ui.guiContractSummary : ui.guiContractDocument}
        </button>
    `);

    const showSuccessInfo = isPuc && !isCartESimOnly;

    if (!cartTariff) {
      return null;
    }
    return (
      <div className={suitcss({}, this)}>
        <div
          className={suitcss({
            descendantName: 'header',
          }, this)}
        >
          <Headline
            utilities={['alignCenter']}
            raw
            size="l"
          >
            {headline}
          </Headline>
          <Copy
            raw
            utilities={['alignCenter']}
            embedded
            onClick={this.manageDownloadEECCPdf}
          >
            {checkoutSummaryPdfLink}
          </Copy>
        </div>
        {showSuccessInfo && <SuccessInfo type="new" />}
        <ShoppingCart
          cart={cart}
          isConfirmed
          skipPromote
          isCheckoutSummary
        />
      </div>
    );
  }
}

CheckoutSummary.propTypes = {
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  cart: PropTypes.array,
  cartTariff: tariffShape,
  dispatch: PropTypes.func,
  processId: PropTypes.string,
  isContractRenewal: PropTypes.bool,
  isCartESimOnly: PropTypes.bool,
  msisdn: PropTypes.string,
  ui: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const { user } = state;
  const { isContractRenewal } = ownProps;
  const processId = isContractRenewal
    ? user.EECC.tariffChange.processId
    : user.EECC.ordering.processId;
  return {
    processId,
    msisdn: user.credentials.msisdn,
  };
};

export default compose(
  connectUI(),
  connect(
    mapStateToProps,
    null,
    null,
    {
      areStatesEqual: (next, prev) => areObjectsEqual(next, prev, {
        user: { EECC: { tariffChange: { processId: {} }, ordering: { processId: {} } } },
      }, false),
      areOwnPropsEqual: (next, prev) => areObjectsEqual(next, prev, {
        isContractRenewal: {},
      }, false),
    },
  ),
)(CheckoutSummary);
