import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import suitcss from '../../../helpers/suitcss';
import matchMediaConnector from '../../../containers/service/ServiceMatchMedia';

import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import ShoppingCart from '../../../containers/cart/ShoppingCart';
import { ICON_WHATSAPP_SHARE_BUTTON } from '../../../helpers/constants';
import { createWhatsAppShareLink } from '../../../helpers/socialMediaLinkShare';
import CheckoutCartFaqFooter from './CheckoutCartFaqFooter';
import { areObjectsEqual } from '../../../helpers/objectEquals';


class CheckoutCart extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = { faqsToggled: false };
    this.onToggleFaqs = this.onToggleFaqs.bind(this);
  }

  onToggleFaqs(ev) {
    ev.preventDefault();
    this.setState({ faqsToggled: !this.state.faqsToggled });
  }

  render() {
    const {
      stepId,
      theme,
      headline,
      faqs,
      labelSubmit,
      urlSubmit,
      hintSubmit,
      isConfirmed,
      isSubmitDisabled,
      isSubmitOptional,
      isMediaS,
      ui,
      isContractRenewal,
      cartHardware,
      cartTariff,
      isMobileCheckout,
    } = this.props;

    const isCompact = theme === 'compact';
    const stepFaqs = faqs && faqs.filter(faq => faq.id.endsWith(stepId));
    const whatsAppLink = createWhatsAppShareLink(cartTariff, cartHardware, '?fillCart=1');
    return (
      <section className={suitcss({ modifiers: [theme] }, this)}>
        <div className={suitcss({ descendantName: 'header' }, this)} >
          {headline && !isCompact &&
            <div className={suitcss({ descendantName: 'title' }, this)}>
              <Headline size="l" embedded raw >{headline}</Headline>
              {(ui.scaThumbTitle || ui.scaThumbSubline) &&
                <div className={suitcss({ descendantName: 'thumb', utilities: ['marginTopS'] }, this)}>
                  <div className={suitcss({ utilities: ['marginLeftS'] }, this)}>
                    <Copy size="secondary" utilities={['weightBold']} condensed embedded uppercase>{ui.scaThumbTitle}</Copy>
                    <Copy size="secondary" condensed embedded>{ui.scaThumbSubline}</Copy>
                  </div>
                </div>
              }
            </div>
          }
          {!isCompact && <Link
            element={!urlSubmit ? 'button' : null}
            to={urlSubmit}
            buttonFilled={!isSubmitOptional}
            isDisabled={isSubmitDisabled}
            asButton
          >
            {labelSubmit || ui.scaOrderNow}
          </Link>}
        </div>
        <div className={suitcss({ descendantName: 'cart' }, this)} >
          <ShoppingCart
            theme={theme}
            isInitiallyOpen={!isMobileCheckout}
            isConfirmed={isConfirmed || isCompact}
            withPromoCodeForm
            isMobileCheckout={isMobileCheckout}
          />
        </div>
        {!isMediaS && isCompact &&
          <Link
            element={!urlSubmit ? 'button' : null}
            to={urlSubmit}
            buttonFilled={!isSubmitOptional}
            isDisabled={isSubmitDisabled}
            asButton
          >
            {isContractRenewal ? ui.cfoPlaceRenewal : labelSubmit || ui.scaOrderNow}
          </Link>
        }
        {hintSubmit && isCompact &&
        <Copy
          className={suitcss({
            descendantName: 'hint',
            utilities: ['alignCenter', 'colorGray50'],
          }, this)}
          size="small"
          embedded
          raw
        >
          {hintSubmit}
        </Copy>
        }
        {!isCompact &&
          <div className={suitcss({ descendantName: 'footer' }, this)} >
            <Link
              asButton
              to={urlSubmit}
              buttonFilled={!isSubmitOptional}
              isDisabled={isSubmitDisabled}
            >
              {labelSubmit || ui.scaOrderNow}
            </Link>
            <div
              className={suitcss({
                descendantName: 'button',
              }, this)}
            >
              <Link
                icon={ICON_WHATSAPP_SHARE_BUTTON}
                asShareButton
                utilities={['sMarginTopL', 'flex', 'itemsCenter', 'justifyCenter', 'mlHidden']}
                href={whatsAppLink}
              >
                {ui.guiButtonWhatsapp}
              </Link>
            </div>
          </div>
        }
        {isCompact && stepFaqs && !!stepFaqs.length &&
          <div className={suitcss({ descendantName: 'footer' }, this)} >
            {!isMobileCheckout && <CheckoutCartFaqFooter faqs={stepFaqs} ui={ui} />}
            {isMediaS && isContractRenewal &&
            <div
              className={suitcss({
                descendantName: 'note',
                utilities: [],
              }, this)}
            >
              <Copy
                raw
                embedded
                className={suitcss({
                  descendantName: 'noteInner',
                }, this)}
              >
                {ui.txtCfoVvlConfirmationHint}
              </Copy>
            </div>
            }
          </div>
        }
      </section>
    );
  }
}

CheckoutCart.propTypes = {
  theme: PropTypes.oneOf(['full', 'compact']),
  stepId: PropTypes.string,
  headline: PropTypes.string,
  urlSubmit: PropTypes.string,
  labelSubmit: PropTypes.string,
  hintSubmit: PropTypes.string,
  faqs: PropTypes.array,
  isConfirmed: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  isSubmitOptional: PropTypes.bool,
  isMediaS: PropTypes.bool,
  isContractRenewal: PropTypes.bool,
  cartHardware: PropTypes.shape({
    iid: PropTypes.string.isRequired,
  }),
  isMobileCheckout: PropTypes.bool,
  cartTariff: PropTypes.object,
  ui: PropTypes.shape({
    scaOrderNow: PropTypes.string.isRequired,
    scaThumbTitle: PropTypes.string,
    scaThumbSubline: PropTypes.string,
  }),
};

CheckoutCart.defaultProps = {
  theme: 'full',
  faqs: [],
};

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
    isContractRenewal: state.site.contractRenewal.isInProgress,
  };
};

export default compose(
  connect(
    mapStateToProps,
    null,
    null,
    {
      areStatesEqual: (next, prev) => areObjectsEqual(next, prev, {
        ui: {},
        site: { contractRenewal: { isInProgress: {} } },
      }, false),
    },
  ),
  memo,
  matchMediaConnector(['isMediaS']),
)(CheckoutCart);
