import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import escape from 'html-escape';
import { isHardwareEntity } from '../../helpers/entity';

import enrichCartEntities from '../preloader/PreloaderCart';
import GlobalSection from '../../components/basics/global/GlobalSection';
import CheckoutSummaryComposition from '../../components/compositions/checkout/CheckoutSummary';
import ServiceRiskIdent from '../../components/basics/service/ServiceRiskIdent';

import { shape as tariffShape } from '../../propTypes/tariff';
import { E_SIM, QUERY_ORDER_ID } from '../../helpers/constants';
import { sanitizeLocationParams } from '../../helpers/url';
import { areObjectsEqual } from '../../helpers/objectEquals';

function CheckoutSummary({
  cartTariff,
  params,
  contractId,
  cart,
  isContractRenewal,
  isCartESimOnly,
}) {
  const copy = params.copy.replace('{ORDER_NUMBER}', escape(contractId));
  return (
    <GlobalSection>
      <CheckoutSummaryComposition
        cartTariff={cartTariff}
        headline={params.headline}
        copy={copy}
        contractId={contractId}
        cart={cart}
        isContractRenewal={isContractRenewal}
        isCartESimOnly={isCartESimOnly}
      />
      <ServiceRiskIdent
        token={contractId}
        location="Checkout"
      />
    </GlobalSection>
  );
}

CheckoutSummary.propTypes = {
  params: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  cartTariff: tariffShape.isRequired,
  contractId: PropTypes.string.isRequired,
  isContractRenewal: PropTypes.bool,
  isCartESimOnly: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const { order, site, user } = state;
  const { location } = ownProps;
  const { sitemap } = site;
  const orderId = sanitizeLocationParams(location, QUERY_ORDER_ID);
  const { cart } = (order[orderId] || {});
  const response = (order[orderId] && order[orderId].response) || {};
  const isContractRenewal = sitemap.MyContractRenewalSuccessRoute.url === location.pathname
    || sitemap.MyContractRenewalAppSuccessRoute.url === location.pathname;
  const isCartESimOnly = user.cartSimType
    && user.cartSimType === E_SIM && !cart.find(isHardwareEntity);
  return {
    isContractRenewal,
    contractId: response.contractId,
    cart,
    isCartESimOnly,
  };
}

export default compose(
  connect(
    mapStateToProps,
    null,
    null,
    {
      areStatesEqual: (next, prev) => areObjectsEqual(next, prev, {
        order: {},
        site: { sitemap: { MyContractRenewalSuccessRoute: { url: {} } } },
        user: { cartSimType: {} },
      }, false),
      areOwnPropsEqual: (next, prev) => areObjectsEqual(next, prev, {
        location: {},
      }, false),
    },
  ),
  enrichCartEntities(),
)(CheckoutSummary);
