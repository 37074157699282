import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';
import ButtonCloseIcon from '../button/ButtonCloseIcon';
import connectSiteProvider from '../service/ServiceSiteConnector';

function UIOverlayWrapper(props) {
  const {
    className,
    children,
    visible,
    alignH,
    alignV,
    alignContent,
    theme,
    onClose,
    style,
    withoutPadding,
    isScrollbar,
    ui,
  } = props;
  const states = [visible !== false && 'visible'];
  const modifiers = [
    theme && `theme${capitalize(theme)}`,
    alignH && `align${capitalize(alignH)}`,
    alignV && `align${capitalize(alignV)}`,
    alignContent && `align${capitalize(alignContent)}`,
    !withoutPadding && 'padding',
    isScrollbar && 'scrollbar',
  ];

  return (
    <div className={suitcss({ modifiers, states, className }, this)} style={style}>
      <div className={suitcss({ descendantName: 'container' }, this)}>
        {onClose && (
          <ButtonCloseIcon className={suitcss({ descendantName: 'close' }, this)} onClick={onClose} ariaLabel={ui.myOteloPopupCloseAlt} />
        )}
        <div className={suitcss({ descendantName: 'inner' }, this)}>
          {children}
        </div>
      </div>
    </div>
  );
}

UIOverlayWrapper.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  theme: PropTypes.oneOf([
    'light',
    'medium',
    'dark',
    'custom',
    'cookieconsent',
    'cookieconsentsettings',
    'consentinquiry',
    'simarticlelist',
  ]),
  alignH: PropTypes.oneOf(['left', 'right', 'center']),
  alignV: PropTypes.oneOf(['top', 'bottom', 'middle']),
  alignContent: PropTypes.oneOf(['inside', 'outside']),
  onClose: PropTypes.func,
  isScrollbar: PropTypes.bool,
  style: PropTypes.object,
  withoutPadding: PropTypes.bool,
  ui: PropTypes.shape({
    myOteloPopupCloseAlt: PropTypes.string,
  }),
};

const mapStateToProps = (state, ownProps) => {
  const { siteProvider } = ownProps;
  const ui = siteProvider.getGlobalUi();

  return { ui: ownProps.ui || ui };
};

export default compose(
  connectSiteProvider(),
  connect(mapStateToProps),
)(UIOverlayWrapper);
