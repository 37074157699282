
import { push, replace } from 'react-router-redux';

import { showDialog, hideDialog } from '../page/dialog';
import
ContractRenewalOptionsDialog
  from '../../components/compositions/dialog/ContractRenewalOptionsDialog';
import { replaceCart, clearCart } from '../order/cart';
import { send } from '../request/send';
import {
  fetchEntityById,
  fetchTariffs,
  fetchContractData,
  fetchEntityByInternalId,
} from '../request/registry';

import { toDecimal } from '../../helpers/money';
import { obtainCredentials } from './misc';
import { formatDate, getTariffVoDate } from '../../helpers/date';
import { getAppEntityName, getWebEntityName } from '../../helpers/entity';
import * as constants from '../../helpers/constants';
import { isAppContextContractRenewal } from '../../helpers/site';
import { isTariffRenewable } from '../../helpers/user';
import { trackMyState } from '../tracking/event';
import * as bridge from '../../services/bridge';
import { getBookablePromotions, getMatchingPromotions } from '../../helpers/promotions';
import { fireTariffChangeCompleteTriggerEvent } from '../../services/triggerEvents';
import EECCTariffChangeConfirmRequest from '../../model/requests/EECCTariffChangeConfirmRequest';
import EECCTariffChangeInitiateRequest from '../../model/requests/EECCTariffChangeInitiateRequest';
import EECCTariffChangeDocumentRequest from '../../model/requests/EECCTariffChangeDocumentRequest';
import EECCCheckSummaryConsentDialogCopy from '../../components/compositions/dialog/EECCCheckSummaryConsentDialogCopy';
import { bindQueryParams } from '../../helpers/url';

export const CONTRACT_RENEWAL_START = `${constants.ACTION_PREFIX}/CONTRACT_RENEWAL_START`;
export const CONTRACT_RENEWAL_STOP = `${constants.ACTION_PREFIX}/CONTRACT_RENEWAL_STOP`;

/**
 * Will remove contract-renewal related data from the store.
 */
export const stopContractRenewal = () => (dispatch, getState) => {
  const { site } = getState();
  dispatch(clearCart());
  dispatch({ type: CONTRACT_RENEWAL_STOP });
  // WARNING! the push needs to be done after the contract renewal has been
  // stopped since we listen to the change in the router and show a dialog
  // if the user attempts to change the url.
  // @todo create a route "MyContractRenewalAppAbortRoute" that we can check against in routes.js
  dispatch(push(site.sitemap.MyDashboardRoute.url));
};

/**
 * Called once contract renewal has been successful.
 *
 * Note: calling this function will automatically stop the contract renewal.
 */
export const contractRenewalSuccess = () => (dispatch, getState) => {
  dispatch(stopContractRenewal());
  if (isAppContextContractRenewal(getState().site)) {
    bridge.contractRenewalSuccess();
  }
};

/**
 * Activates the contract renewal for our site and redirects the
 * user to the specified location.
 *
 * @param {string} redirectUrl
 */
export const startContractRenewal = redirectUrl => async dispatch => {
  await dispatch(fetchTariffs({ isBlocking: true }));
  dispatch({ type: CONTRACT_RENEWAL_START });
  dispatch(hideDialog()); // just in case
  if (redirectUrl) {
    await dispatch(replace(redirectUrl));
  }
};

/**
 * Dialog should be displayed in case the user decides to navigate away from
 * a contract renewal related page or otherwise wants to abort the process.
 */
export const cancelContractRenewal = () => (dispatch, getState) =>
  new Promise(resolve => {
    const { ui } = getState();
    dispatch(showDialog({
      headline: ui.myVvlCancelHeadline,
      copy: ui.txtMyVvlCancelDialog,
      onClose: () => resolve(false),
      actions: [
        {
          label: ui.myVvlCancel,
          withoutArrow: true,
          action: () => {
            dispatch(stopContractRenewal());
            dispatch(hideDialog());
            resolve(true);
          },
        },
        {
          label: ui.guiWordForward,
          asLink: true,
          action: () => {
            dispatch(hideDialog());
            resolve(false);
          },
        },
      ],
    }));
  });

const showChangeSuccessDialog = () => async (dispatch, getState) => {
  const { ui } = getState();
  dispatch(trackMyState(constants.MYTRACK_CONTRACT_CHANGE_DIALOG_SUCCESS));
  dispatch(showDialog({
    headline: ui.myVvlTariffChangeSuccessHeadline,
    copy: ui.myVvlTariffChangeSuccessCopy,
    actions: [{ label: ui.guiWordAllright }],
  }));
};

/**
 * User has to explicitly confirm the tariff change.
 *
 * @param {AppEntity} tariffVO
 */
const showConfirmChangeDialog = tariffVO => async (dispatch, getState) => {
  const { site, user, ui } = getState();
  const { billingMode } = await dispatch(fetchContractData());
  const { bookedTariffs } = await dispatch(fetchTariffs({ isBlocking: true }));
  const bookedTariffVO = bookedTariffs[0];
  const headline = ui.myVvlTariffChangeHeadline;
  const copy = ui.myVvlTariffChangeConfirmCopy
    .replace('{DATE}', formatDate(getTariffVoDate(tariffVO, constants.CONTRACT_TARIFF_CHANGE)))
    .replace('{TARIFF_OLD}', getAppEntityName(bookedTariffVO))
    .replace('{TARIFF_NEW}', getAppEntityName(tariffVO))
    .replace('{PRICE}', `${toDecimal({ unit: tariffVO.basicFee })} ${ui.guiSymbolEuro}`);
  const tariffVE = await dispatch(fetchEntityById(site.internalIdMap[tariffVO.id]));
  const matchingPromos = getMatchingPromotions(
    getBookablePromotions(getState()),
    constants.CONTEXT_TARIFF_CHANGE,
    [tariffVE],
  );

  // INITIATE

  const initResponse = await dispatch(send(new EECCTariffChangeInitiateRequest(
    user.credentials.msisdn,
    {
      product: {
        tariff_identifier: tariffVO.id,
        // according to DBNHAMOP-4465 tariff change should be able to accept promoCodes
        ...(user.promoCodes && user.promoCodes[0] && { promo_code: user.promoCodes[0] }),
        promotions: matchingPromos.map(promo => ({
          promotion_id: promo.iid,
        })),
      },
    },
  )));

  const actions = [
    {
      label: ui.myVvlActionConfirm,
      action: async () => {
        const { msisdn } = user.credentials;
        dispatch(trackMyState(constants.MYTRACK_CONTRACT_CHANGE_DIALOG_SUBMIT));
        await dispatch(send(new EECCTariffChangeConfirmRequest(
          msisdn,
          initResponse.body.data.processId,
          { contract_summary_confirmed: true },
        )));

        if (billingMode === constants.BILLING_MODE_POST) {
          dispatch(push(site.sitemap.MyTariffChangeSuccessRoute.url));
          dispatch(hideDialog());
        } else {
          dispatch(push(site.sitemap.MyDashboardRoute.url));
          dispatch(showChangeSuccessDialog());
        }
        fireTariffChangeCompleteTriggerEvent(getState, dispatch, tariffVO.id);
      },
      expanded: true,
    },
    {
      label: ui.guiWordCancel,
      asLink: true,
    },
  ];

  dispatch(trackMyState(constants.MYTRACK_CONTRACT_CHANGE_DIALOG));
  dispatch(showDialog({
    headline,
    copy,
    actions,
    withCloseAction: false,
    component: EECCCheckSummaryConsentDialogCopy,
    props: {
      DocumentRequest: EECCTariffChangeDocumentRequest,
      processId: initResponse.body.data.processId,
      showInfoI: billingMode === constants.BILLING_MODE_POST,
      showEECCCopy: !!(ui.tariffsCsPlaceholder),
      label: ui.abfTariffSwapHintStep3,
      copy: ui.txtAbfBillingModeCustomerCopy,
      asAccordion: true,
      withContentBox: true,
      eeccText: ui.tariffsCsHint,
      eeccTextSuffix: ui.tariffsCsPlaceholder,
      isAutomaticExtension: true,
    },
    onClose: () => dispatch(trackMyState(constants.MYTRACK_CONTRACT_CHANGE_DIALOG_CANCEL)),
  }));
};

/**
 * Will start the actual contract renewal process with the specified tariff
 *
 * @param {string} iid - the tariff iid
 * @param {boolean} withHardwareSelection - whether or not the user is presented a list of
 *    hardware; this does not necessarily mean the user is required to choose a hardware!
 */
export const startContractRenewalFromTariff = (iid, withHardwareSelection) =>
  async (dispatch, getState) => {
    const { site } = getState();
    const tariffVE = await dispatch(fetchEntityByInternalId(iid));
    let url;
    if (withHardwareSelection) {
      url = tariffVE.urlSelect;
    } else {
      url = site.sitemap.ShoppingCartRoute.url;
      dispatch(replaceCart([tariffVE]));
    }

    await dispatch(startContractRenewal(url));
  };

/**
 * Will start the actual contract renewal process on the hardware detail page
 * with the specified tariff and hardware
 *
 * @param {string} iid - the tariff iid
 * @param {string} hardwareId - the hardware id
 */
export const startContractRenewalFromTariffWithSelectedHardware = (iid, hardwareId) =>
  async (dispatch) => {
    const tariffVE = await dispatch(fetchEntityByInternalId(iid));
    const hardwareVE = await dispatch(fetchEntityById(hardwareId));
    const url = bindQueryParams(hardwareVE.urlDetails, {
      [constants.QUERY_SELECTED_HARDWARE]: hardwareId,
      [constants.QUERY_SELECTED_TARIFF]: tariffVE.eid,
    });

    await dispatch(startContractRenewal(url));
  };

/**
 * Depending on whether the tariff permits a renewal or a change,
 * the user is offered different options how to proceed.
 *
 * @param {AppEntity} bookedTariffVO
 * @param {AppEntity} selectedTariffVO
 * @param {object} options
 */
const showBookingOptionsDialog =
  (bookedTariffVO, selectedTariffVO, options = {}) => async (dispatch, getState) => {
    const { site, ui } = getState();
    const tariffName = getAppEntityName(selectedTariffVO);
    const actions = [];
    const isSameTariff = bookedTariffVO.id === selectedTariffVO.id;

    const headline = (isSameTariff ? ui.myVvlHeadlineTariffEquals : ui.myVvlHeadlineTariffAvailable)
      .replace('{TARIFF}', tariffName);
    let copy;
    let isVvl;

    if (!options.isForceTariffChange && isTariffRenewable(selectedTariffVO)) {
      copy = selectedTariffVO.contractRenewalWithSimOnly
        ? ui.myVvlCopyExtend
        : ui.myVvlCopyExtendVvvl;
      actions.push({
        label: ui.myVvlActionExtend,
        action: async () => {
          const tariffVE = await dispatch(fetchEntityByInternalId(selectedTariffVO.id));
          dispatch(startContractRenewal(tariffVE.urlSelect));
        },
        expanded: true,
      });
      isVvl = true;
    } else {
      copy = ui.myVvlCopyChange;
      actions.push(
        {
          label: ui.myVvlActionChange,
          action: () => dispatch(showConfirmChangeDialog(selectedTariffVO)),
          expanded: true,
        },
        {
          label: ui.guiWordCancel,
          asLink: true,
        },
      );
      isVvl = false;
    }
    const isWebviewContractRenewal = isAppContextContractRenewal(site);
    dispatch(showDialog({
      component: ContractRenewalOptionsDialog,
      props: {
        ui,
        copy,
        withoutComparison: isSameTariff,
        bookedTariffVO,
        selectedTariffVO,
        renewalType: isVvl
          ? (selectedTariffVO.contractRenewalWithSimOnly
            ? constants.CONTRACT_RENEWAL_WITHOUT_HARDWARE
            : constants.CONTRACT_RENEWAL_WITH_HARDWARE)
          : constants.CONTRACT_TARIFF_CHANGE,
      },
      withCloseAction: !isWebviewContractRenewal,
      headline,
      actions,
    }));
  };

/**
 * Dialog is shown booking failed and the process was started
 * in the hardware details section.
 *
 * @param {AppEntity} bookedTariffVO
 * @param {WebEntity} selectedTariffVE
 */
const showTariffNotAvailableDialog = (bookedTariffVO, selectedTariffVE) => (dispatch, getState) => {
  const { site, ui } = getState();
  const { sitemap } = site;
  const isSameTariff = bookedTariffVO.id === selectedTariffVE.iid;
  const tariffName = getWebEntityName(selectedTariffVE);
  dispatch(showDialog({
    headline: (isSameTariff ? ui.myVvlHeadlineTariffEquals : ui.myVvlHeadlineTariffUnavailable)
      .replace('{TARIFF}', tariffName),
    copy: ui.myVvlCopyChangeUnavailable.replace('{TARIFF}', tariffName),
    actions: [
      {
        label: ui.guiWordProceedDashboard,
        action: () => {
          dispatch(push(sitemap.MyDashboardRoute.url));
          dispatch(hideDialog());
        },
        expanded: true,
      },
    ],
  }));
};

/**
 * Starts the booking process from the tariff details page within the presales section.
 *
 * To determine whether a tariff is bookable or not, we need to iterate over
 * the list of bookable tariffs and check whether the selected tariff is bookable.
 *
 * @param {WebEntity} tariffVE - the selected tariff
 */
export const initBooking = tariffVE => async dispatch => {
  // ensure that the user is logged in!
  await dispatch(obtainCredentials());
  const {
    bookedTariffs,
    bookableTariffs,
  } = await dispatch(fetchTariffs({ isBlocking: true }));

  // is tariff either renewable or changeable?
  const selectedTariffVO = bookableTariffs.find(tVO => tVO.id === tariffVE.iid);
  const bookedTariffVO = bookedTariffs[0];

  if (!selectedTariffVO) {
    dispatch(showTariffNotAvailableDialog(bookedTariffVO, tariffVE));
    return;
  }

  dispatch(showBookingOptionsDialog(bookedTariffVO, selectedTariffVO));
};

/**
 * Shows the tariff change dialog.
 *
 * Note: only dispatch this action if logged in and you checked whether a tariff
 * change is possible.
 *
 * @param selectedTariffVO
 */
export const showTariffChangeDialog = selectedTariffVO => async dispatch => {
  const { bookedTariffs } = await dispatch(fetchTariffs({ isBlocking: true }));
  dispatch(showBookingOptionsDialog(bookedTariffs[0], selectedTariffVO, {
    isForceTariffChange: true,
  }));
};

/**
 * Dialog is shown booking failed and the process was started
 * in the hardware details section.
 */
const showRenewalImpossibleDialog = () => (dispatch, getState) => {
  const { ui } = getState();
  dispatch(trackMyState(constants.MYTRACK_CONTRACT_CHANGE_DIALOG_IMPOSSIBLE));
  dispatch(showDialog({
    headline: ui.myVvlHeadlineExtendUnavailable,
    copy: ui.myVvlCopyExtendUnavailable,
    actions: [
      {
        label: ui.guiWordAllright,
        expanded: true,
      },
    ],
  }));
};

export const showContractRenewalFeeInfoDialog = (contractRenewalFee, global) =>
  (dispatch, getState) => {
    const { ui, user } = getState();
    const { cancellationDate, possibleCancellationDate } = user.contractData;
    const date = cancellationDate
      ? formatDate(cancellationDate)
      : formatDate(possibleCancellationDate);
    dispatch(showDialog({
      title: ui.tariffsEarlyRenewal,
      copy: (global
        ? ui.txtTariffsEarlyFeeGlobalExplanation
        : ui.txtTariffsEarlyFeeConditionExplanation
      )
        .replace(/{PRICE}/g, contractRenewalFee)
        .replace(/{CONTRACT_DISMISSAL_DATE}/g, date),
      actions: [
        {
          label: ui.guiWordAlright,
          action: async () => {
            dispatch(hideDialog());
          },
          withoutArrow: true,
        },
      ],
    }));
  };

/**
 * Starts the booking process from the hardware details page within the presales section.
 *
 * To check whether a booking is possible, we get all available tariffs for the hardwware via the
 * hardware's tariffMap. Then we check whether at least one of these tariffs is included in
 * the list of bookable tariffs. If this is the case, we start the contract renewal process by
 * jumping directly to the HardwarTariff view with the first tariff selected.
 *
 * @param {WebEntity} hardwareVE - the selected hardware
 */
export const initBookingFromHardware = hardwareVE => async (dispatch, getState) => {
  const { site } = getState();
  await dispatch(obtainCredentials());
  const { renewableTariffs } = await dispatch(fetchTariffs({ isBlocking: true }));

  // eslint-disable-next-line array-callback-return, no-shadow
  const tariffVO = renewableTariffs.find(tariffVO => {
    const eid = (site.internalIdMap[tariffVO.id] || '').toLowerCase();
    return !!hardwareVE.tariffMap[eid];
  });

  if (tariffVO) {
    dispatch(startContractRenewal(hardwareVE.urlSelect));
    return;
  }

  dispatch(showRenewalImpossibleDialog());
};
