import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { replaceCart as replaceCartAction } from '../../actions/order/cart';
import * as contractRenewalActions from '../../actions/dialog/contractRenewalActions';

import { isTariffEntity } from '../../helpers/entity';
import { QUERY_SELECTED_HARDWARE, MARKET_POSTPAID, MARKETING_LOCATION_TARIFF_DETAILS } from '../../helpers/constants';
import { CATEGORY_SINGLE_PRODUCT } from '../../propTypes/tariff';

import Callout from '../callout/Callout';
import GlobalSection from '../../components/basics/global/GlobalSection';
import TariffDetailsComposition from '../../components/compositions/tariff/TariffDetails';
import imageSizes from '../../config/imageSizes';
import * as registry from '../../actions/request/registry';
import { addItem, removeItem } from '../../actions/order/process';
import { createNormalizeTargetEntitiesSelector } from '../../selectors/entity';
import { areObjectsEqual } from '../../helpers/objectEquals';

class TariffDetail extends PureComponent {

  componentDidMount() {
    const { fetchTariffOptions, tariff } = this.props;
    fetchTariffOptions(tariff.options);
  }

  render() {
    const {
      params,
      tariff,
      selectedHardware,
      replaceCart,
      primaryModule,
      initBooking,
      isContractRenewal,
      tariffOptions,
      addOrderItem,
      removeOrderItem,
      orderProcess,
      selectedTariffOptionIds,
      shoppingCartRoute,
    } = this.props;

    const onExtendContract = () => initBooking(tariff);
    const tariffIsSingleProduct = tariff.category === CATEGORY_SINGLE_PRODUCT;
    const isHardwareSelected = !!selectedHardware;
    const shouldLinkToShoppingCart =
      tariffIsSingleProduct ||
      isHardwareSelected ||
      (tariff.simOnly && tariff.market === MARKET_POSTPAID);
    const onBeforeSelect = shouldLinkToShoppingCart ?
      selectedTariff => replaceCart([selectedHardware, selectedTariff].filter(e => !!e)) : null;
    const onSelect = selectedTariff => replaceCart([selectedTariff].filter(e => !!e));
    return (
      <div>
        <Callout theme="label" targets={[tariff]} bordered expanded />
        <GlobalSection
          layout="contained"
          image={tariff.backgroundImage}
          imageAlign="left"
          imageSizes={imageSizes.tariffDetailsBackground}
        >
          <TariffDetailsComposition
            primaryModule={primaryModule}
            headline={params.headline}
            footer={params.footer}
            tariff={tariff}
            tariffOptions={tariffOptions}
            features={params.features}
            isHardwareSelected={isHardwareSelected}
            onBeforeSelect={onBeforeSelect}
            onSelect={onSelect}
            onExtendContract={onExtendContract}
            isContractRenewal={isContractRenewal}
            urlSelect={shouldLinkToShoppingCart ?
              params.urlShoppingCart :
              tariff.urlSelect
            }
            orderProcess={orderProcess}
            addOrderItem={addOrderItem}
            removeOrderItem={removeOrderItem}
            selectedTariffOptionIds={selectedTariffOptionIds}
            shoppingCartRoute={shoppingCartRoute}
          />
        </GlobalSection>
      </div>
    );
  }
}

TariffDetail.getSelectedProduct = (state, { entities, location }) => ({
  selectedTariff: entities.find(isTariffEntity),
  // hardware is required for orderProcess
  selectedHardware: location.query[QUERY_SELECTED_HARDWARE] && {
    etype: 'hardwareEntity',
    eid: location.query[QUERY_SELECTED_HARDWARE],
  },
});

TariffDetail.propTypes = {
  params: PropTypes.object.isRequired,
  primaryModule: PropTypes.bool.isRequired,
  isContractRenewal: PropTypes.bool.isRequired,
  tariff: PropTypes.object.isRequired,
  selectedHardware: PropTypes.object,
  replaceCart: PropTypes.func,
  initBooking: PropTypes.func.isRequired,
  fetchTariffOptions: PropTypes.func.isRequired,
  tariffOptions: PropTypes.array,
  shoppingCartRoute: PropTypes.object,
  addOrderItem: PropTypes.func,
  removeOrderItem: PropTypes.func,
  orderProcess: PropTypes.object,
  selectedTariffOptionIds: PropTypes.array,
};

function makeStateToProps() {
  const normalizeTargetEntitySelector = createNormalizeTargetEntitiesSelector({ style: 'strike' });
  const normalizeTargetEntitiesSelector = createNormalizeTargetEntitiesSelector({ style: 'strike' });
  return (state, ownProps) => {
    const { entities, orderProcess } = state;
    const { ui } = ownProps;
    const { selectedTariff, selectedHardware } = TariffDetail.getSelectedProduct(state, ownProps);
    const tariffOptions = selectedTariff.options
      .map(id => entities.tariffOptionEntity[id])
      .filter(entry => entry &&
        entry.marketingLocation.includes(MARKETING_LOCATION_TARIFF_DETAILS));
    const tariff = normalizeTargetEntitySelector(
      state,
      [
        selectedTariff,
        selectedHardware
          ? entities.hardwareEntity[selectedHardware.eid]
          : null,
      ],
    )[0];
    const selectedTariffOptionIds = tariffOptions
      .map(entry => orderProcess.entities.find(entity => entry.eid === entity.eid) && entry.eid)
      .filter(entry => entry);
    const normalizedTariffOptions = normalizeTargetEntitiesSelector(state, tariffOptions);
    return {
      shoppingCartRoute: state.site.sitemap.ShoppingCartRoute,
      selectedHardware,
      isContractRenewal: state.site.contractRenewal.isInProgress,
      tariff,
      ui,
      tariffOptions: normalizedTariffOptions,
      selectedTariffOptionIds,
      orderProcess,
    };
  };
}

const mapDispatchToProps = {
  replaceCart: replaceCartAction,
  initBooking: contractRenewalActions.initBooking,
  fetchTariffOptions: registry.fetchEntitiesByIds,
  addOrderItem: addItem,
  removeOrderItem: removeItem,
};

const Container = connect(
  makeStateToProps,
  mapDispatchToProps,
  null,
  {
    areStatesEqual: (next, prev) => areObjectsEqual(next, prev, {
      orderProcess: { entities: {} },
      entities: {},
      site: { sitemap: { ShoppingCartRoute: {} }, contractRenewal: {} },
      user: { esimStatusResponse: {}, contractData: { earlyContractRenewalFee: {} } },
      routing: {},
    }),
  },
)(TariffDetail);
Container.getSelectedProduct = TariffDetail.getSelectedProduct;

export default Container;
