import { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change } from 'redux-form';

import {
  clearDeniedPromoCodes,
  registerGrantedCampaignPromotions,
  registerPromoCodes,
  removeCampaignToken,
} from '../../actions/user/marketing';
import initForm from '../form/FormInitializer';
import {
  PRIVATE_PROMO_CODE_PREFIX,
} from '../../helpers/constants';
import
PromotionCodeForm, {
  CODE_STATE_VALID,
  CODE_STATE_PENDING,
  CODE_STATE_PRIVATE,
} from '../../components/compositions/promotion/PromotionCodeForm';
import { getGrantedPromotions } from '../../helpers/promotions';
import { fetchGrantedPromotions, fetchMyPromotions } from '../../actions/request/registry';
import { trackError } from '../../actions/tracking/event';
import { areObjectsEqual } from '../../helpers/objectEquals';

const createFieldMap = ({ ui }) => ({
  promoCode: {
    name: '_promoCode',
    label: ui.promotionCodeLabel,
    validation: {
      range: [5],
    },
  },
});

const mapStateToProps = ({
  user: { promoCodes, deniedPromotions, grantedPromotions },
  lifecycle: { isBlockingRequestInProgress },
  site: { contractRenewal } = {},
  entities,
}) => {
  const isContractRenewal = contractRenewal && contractRenewal.isInProgress;
  const promotions = getGrantedPromotions({ user: { grantedPromotions }, entities });
  // if the promo code is marked as private, we remove the privat marker
  // so the user doesn't see the actual code.
  const promoCode = promoCodes && promoCodes[0];
  const deniedPromoCode = deniedPromotions.promoCodes && deniedPromotions.promoCodes[0];
  // Validation check is just looking for a promotion with promoCode in the required parameters
  // This is for the code state message in the PromotionCodeForm
  const hasValidPromotionWithCode = promotions.some(p => p.requiredParameters.includes('promoCode'));

  const isPrivateCode = promoCode && promoCode.startsWith(PRIVATE_PROMO_CODE_PREFIX);

  const codeState = isBlockingRequestInProgress
    ? CODE_STATE_PENDING
    : isPrivateCode
      ? CODE_STATE_PRIVATE
      : hasValidPromotionWithCode
        ? CODE_STATE_VALID
        : null;

  return {
    codeState,
    promoCode,
    deniedPromoCode,
    isContractRenewal,
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    form,
    fieldMap,
    formValues,
    onAfterSubmit,
  } = ownProps;
  const { dispatch } = dispatchProps;
  const { isContractRenewal } = stateProps;
  // We fetch the granted promotions here as we registered the entered promo code
  // just right before.
  // Promotions with the promo code as param get fetched and give us the information,
  // if there is a promoCode in the required parameters.
  // This is our validation check!

  // according to DBNHAMOP-4465 vvl should be able to accept promoCodes
  const isValidPromoCode = async () => {
    const promotions = isContractRenewal
      ? await dispatch(fetchMyPromotions({ isBlocking: true }, true))
      : await dispatch(fetchGrantedPromotions({ isBlocking: true }));
    return promotions.some(p => p.requiredParameters.includes('promoCode'));
  };
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    addPromoCode: async (e, message, error) => {
      if (e) e.preventDefault();
      await dispatch(clearDeniedPromoCodes());
      const fieldName = fieldMap.promoCode.name;
      const promoCode = formValues[fieldName];
      if (promoCode) {
        await dispatch(registerPromoCodes([promoCode]));
        const isValidCode = await isValidPromoCode();
        if (isValidCode) {
          await dispatch(removeCampaignToken());
          await dispatch(registerGrantedCampaignPromotions([]));
        }
        if (error) {
          dispatch(trackError(false, 'validation', 'Promo code invalid.', promoCode, fieldName));
        }
        if (onAfterSubmit) {
          onAfterSubmit(promoCode, isValidCode);
        }
        dispatch(change(form, fieldMap.promoCode.name, ''));
      }
    },
  };
};

// init component once, outside
const component = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    areOwnPropsEqual: (next, prev) => areObjectsEqual(next, prev, {
      config: 'json',
      fieldMap: 'json',
      formValues: {},
      onAfterSubmit: {},
    }),
    areStatesEqual: (next, prev) => areObjectsEqual(next, prev, {
      site: { contractRenewal: {} },
      user: {},
      lifecycle: { isBlockingRequestInProgress: {} },
      entities: {},
    }),
  }),
  initForm(),
  memo,
)(PromotionCodeForm);

export const id = 'promotionCodeForm';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  component,
  fieldMap: createFieldMap(props),
});

export default mapStateToFormConfig;
